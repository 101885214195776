import * as React from "react"
import { graphql } from 'gatsby'
import { useState } from 'react';
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from '../components/header'
import Validate from '../components/validation.js';
import FieldText from '../components/field_text.js';
import FieldEmail from '../components/field_email.js';
import FieldTextArea from '../components/field_textarea.js';
import FieldErrorMessage from '../components/field_error_message';
import ReCAPTCHA from "react-google-recaptcha";
import FieldRadio from '../components/field_radio.js';
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const SITE_KEY = '6LeDM-MgAAAAAG8sEjez8do5o2lPQDcAIf_lsx6_';

const ContactPage = ({data}) => {
  const entry = data.prismicContactPage

  const recaptchaRef = React.createRef();
  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: false,
    formControls: {
      name: {
        type: 'text',
        value: '',
        placeholder: 'Your name*',
        valid: false,
        touched: false,
        validationRules: {
          isRequired:  true,
        },
        errorMessages: ''
      },
      pets_name: {
        type: 'text',
        value: '',
        placeholder: 'Your pets name*',
        valid: false,
        touched: false,
        validationRules: {
          isRequired:  true,
        },
        errorMessages: ''
      },
      email: {
        type: 'email',
        value: '',
        placeholder: 'Your email*',
        valid: false,
        touched: false,
        validationRules: {
          isRequired: true,
          isEmail: true
        },
        errorMessages: ''
      },
      phone: {
        type: 'text',
        value: '',
        placeholder: 'Phone',
        valid: true,
        touched: false,
        validationRules: '',
        errorMessages: ''
      },
      rating: {
        type: 'radio',
        options: [
            {value: '1', displayValue: '1'},
            {value: '2', displayValue: '2'},
            {value: '3', displayValue: '3'},
            {value: '4', displayValue: '4'},
            {value: '5', displayValue: '5'},
        ],
        value: '',
        placeholder: '',
        valid: false,
        touched: false,
        validationRules: {
            isRequired: true
        },
        errorMessages: ''
      },
      feedback: {
        type: 'text',
        value: '',
        placeholder: 'Your feedback*',
        valid: false,
        touched: false,
        validationRules: {
          isRequired: true
        },
        errorMessages: ''
      },
      files: {
        type: 'file',
        value: '',
        placeholder: '',
        valid: true,
        touched: false,
        validationRules: {
          isRequired: false
        },
        errorMessages: ''
      }
    }
  });

  const changeHandler = e => {
    const name = e.target.name;
    const value = e.target.value;

    const updatedControls = {
      ...formValues.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    updatedFormElement.value = value;
    updatedFormElement.touched = true;

    let validateFormElement = [];
    validateFormElement = Validate(value, updatedFormElement.validationRules);

    updatedFormElement.valid = validateFormElement.isValid;
    updatedFormElement.errorMessages = validateFormElement.errorMessages;

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    setFormValues({
      ...formValues,
      formControls: updatedControls,
      formIsValid: formIsValid,
    });
  }

  const changeFilesHandler = e => {
    const name = e.target.name;
    let value = e.target.files[0];
    //console.log('files[0] ', e.target.files[0]);

    const updatedControls = {
      ...formValues.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    updatedFormElement.value = value;
    updatedFormElement.touched = true;

    updatedControls[name] = updatedFormElement;

    setFormValues({
      ...formValues,
      formControls: updatedControls,
      formIsValid: true,
    });
  }

  const submitHandler = async e => 
  {
    e.preventDefault();
    e.persist();

    let fieldData = new FormData()

    for (let formElementId in formValues.formControls) {
      fieldData.append(formElementId, formValues.formControls[formElementId].value)
    }

    const token = await recaptchaRef.current.executeAsync();
    fieldData.append('recaptcha', token)

    const res = await fetch('/api/contact', {
      method: 'POST',
      body: fieldData,
    });
    
    if (res.status === 200) {
      window.location.href = '/contact/thanks/';
      return
    }

    if (res.status === 422) {
      let errors = await res.json();
      setFormValues({
        ...formValues,
        formErrors: errors.message,
      });
      return
    };

    if (res.status === 404) {
      let errors = await res.json();
      setFormValues({
        ...formValues,
        formErrors: errors.message,
      });
      return
    };
  }

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full object-cover block py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-16 py-16 md:py-36">
            <div className="font-poppins leading-snug text-2xl text-white font-semibold">
              <h2 className="mb-8 leading-tight">
                <span className="bg-white text-3xl font-bold text-orange px-3 py-1 inline-block">{entry.data.tag}</span>
              </h2>
              <p className="ml-3">{entry.data.heading}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white pt-16 pb-8">
        <div className="w-8/12 md:w-7/12 mx-auto max-w-screen-xl text-center font-poppins max-w-none prose prose-h2:text-orange prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium leading-snug">
          <PrismicRichText linkResolver={linkResolver} field={entry.data.text_heading1.richText} />
        </div>
      </div>
      <div className="w-full bg-white pb-8">
        <div className="w-8/12 md:w-7/12 mx-auto max-w-screen-xl text-center font-poppins max-w-none prose prose-h2:text-diamond prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium leading-snug">
          <PrismicRichText linkResolver={linkResolver} field={entry.data.text_heading2.richText} />
        </div>
      </div>
      <div className="w-full bg-white pb-16">
        <div className="prose-h2:leading-snug prose-h2:text-lg md:prose-h2:text-2xl w-8/12 md:w-7/12 mx-auto max-w-screen-xl text-center font-poppins max-w-none prose prose-h2:text-diamond prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium">
          <PrismicRichText linkResolver={linkResolver} field={entry.data.text_heading3.richText} />
        </div>
      </div>
      <div className="w-full bg-white mb-16">
        <div className="w-8/12 md:w-7/12 mx-auto max-w-screen-xl text-center font-poppins max-w-none prose prose-h2:text-diamond prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium leading-snug">
          <PrismicRichText linkResolver={linkResolver} field={entry.data.text.richText} />
        </div>
      </div>
      <div className="mb-16 text-center sm:text-left flex justify-center">
        <a href="https://g.page/r/CUT7OY2Mru36EAE/review" target="_blank" title="Give Feedback" rel="nofollow noopener" className="rounded-full px-9 py-3 font-poppins text-lg font-medium bg-orange text-white">
          Give Feedback
        </a>
      </div>
      {/*
      <form name="contact" encType="multipart/form-data" onSubmit={submitHandler}>
        <input type="hidden" name="form-name" value="contact" />
        <div className="w-full bg-white pb-16 font-poppins font-medium">
          <div className="w-10/12 md:w-8/12 mx-auto max-w-screen-xl">
            <div className="flex gap-3">
              <div className="w-8/12">
                <FieldText
                id="name"
                name="name"
                type={formValues.formControls.name.type}
                placeholder={formValues.formControls.name.placeholder}
                value={formValues.formControls.name.value}
                onChange={changeHandler}
                className={formValues.formControls.name.touched && !formValues.formControls.name.valid ? 'font-medium w-full px-2 py-1 border border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-medium w-full px-2 py-1 border border-diamond-30 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
                />
                <FieldErrorMessage
                  className={formValues.formControls.name.touched && !formValues.formControls.name.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                  errorMessages={formValues.formControls.name.errorMessages}
                />
              </div>
              <div className="w-4/12">
                <span className="w-full bg-orange block py-1 border border-orange font-bold text-white text-base text-center">Feedback Form</span>
              </div>
            </div>
            <div>
              <FieldText
                id="pets_name"
                name="pets_name"
                type={formValues.formControls.pets_name.type}
                placeholder={formValues.formControls.pets_name.placeholder}
                value={formValues.formControls.pets_name.value}
                onChange={changeHandler}
                className={formValues.formControls.pets_name.touched && !formValues.formControls.pets_name.valid ? 'font-medium w-full px-2 py-1 border border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-medium w-full px-2 py-1 border border-diamond-30 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
              />
              <FieldErrorMessage
                className={formValues.formControls.pets_name.touched && !formValues.formControls.pets_name.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                errorMessages={formValues.formControls.pets_name.errorMessages}
              />
            </div>
            <div>
              <FieldEmail
                id="email"
                name="email"
                type={formValues.formControls.email.type}
                placeholder={formValues.formControls.email.placeholder}
                value={formValues.formControls.email.value}
                onChange={changeHandler}
                className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'font-medium w-full px-2 py-1 border border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-medium w-full px-2 py-1 border border-diamond-30 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
              />
              <FieldErrorMessage
                className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                errorMessages={formValues.formControls.email.errorMessages}
              />
            </div>
            <div>
              <FieldText
                id="phone"
                name="phone"
                type={formValues.formControls.phone.type}
                placeholder={formValues.formControls.phone.placeholder}
                value={formValues.formControls.phone.value}
                onChange={changeHandler}
                className='font-medium w-full px-2 py-1 border border-diamond-30 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'
              />
            </div>
            <div className="flex-none md:flex">
              <div className="w-full md:w-6/12">
                <div className="text-diamond-80 ml-2">
                  Your rating (1 being poor and 5 being awesome)*
                </div>
              </div>
              <div className="w-full md:w-6/12">
                <div className={formValues.formControls.rating.touched && !formValues.formControls.rating.valid ? 'mb-1 text-diamond-80' : 'mb-4 text-diamond-80'}>
                  <FieldRadio
                    name="rating"
                    type={formValues.formControls.rating.type}
                    options={formValues.formControls.rating.options}
                    onChange={changeHandler}
                  />
                </div>
              </div>
            </div>
            <div>
              <FieldTextArea
                id="feedback"
                name="feedback"
                type={formValues.formControls.feedback.type}
                placeholder={formValues.formControls.feedback.placeholder}
                value={formValues.formControls.feedback.value}
                onChange={changeHandler}
                className={formValues.formControls.feedback.touched && !formValues.formControls.feedback.valid ? 'font-medium w-full px-2 py-1 border border-orange h-40 focus:border-orange focus:outline-none focus:ring-0' : 'font-medium w-full px-2 py-1 border border-diamond-30 mb-4 h-40 focus:border-diamond-80 focus:outline-none focus:ring-0'}
              />
              <FieldErrorMessage
                className={formValues.formControls.feedback.touched && !formValues.formControls.feedback.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                errorMessages={formValues.formControls.feedback.errorMessages}
              />
            </div>
            <div className="mb-6">
              <label className="w-full block text-center px-3 py-2 text-white font-medium bg-diamond-50 border border-diamond-80">
                Upload a photo of your animal wearing Led &amp; Collared gear! (5MB limit)
                <input 
                  id="files"
                  name="files"
                  type={formValues.formControls.files.type} 
                  onChange={changeFilesHandler}
                  className="hidden" 
                  accept="image/*"
                />
              </label>
            </div>
            <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={SITE_KEY}
            />
            <div className="flex justify-between">
              <button type="submit" className="inline-block bg-diamond px-6 py-3 text-lg text-white font-medium focus:outline-none disabled:text-diamond-50 disabled:cursor-not-allowed" disabled={!formValues.formIsValid}>
                <div className="flex items-center gap-1">
                    Submit &#62;
                </div>
              </button>
              <div className="text-right">
                <p className="text-sm text-diamond-50">*required fields</p>
              </div>
            </div>
          </div>
        </div>
      </form>
      */}
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicContactPage {
    data {
      page_title
      meta_description
      image {
        alt
        gatsbyImageData
      }
      tag
      heading
      text_heading1 {
        richText
      }
      text_heading2 {
        richText
      }
      text_heading3 {
        richText
      }
      text {
        richText
      }
    }
  }
}
`

export default ContactPage
