import React from 'react';

const RadioInput = (props) => {
  return (
    <div className="flex justify-between gap-3">
    {props.options.map(option => (
      <div key={option.value}>
        <label htmlFor={option.name} className="text-base flex items-center">
          {option.displayValue}
          <input 
            id={option.value}
            type={props.type}
            name={props.name}
            value={option.value}
            onChange={props.onChange}
            className={props.touched && !props.valid ? 'error' : 'ml-2 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0 focus:ring-1' }
          />
        </label>
      </div>
    ))}
    </div>
  );
}

export default RadioInput;